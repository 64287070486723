<template>
  <div>
    <p class="text-center font-weight-bolder font-medium-5 mb-5">
      {{ $t('New Notifications Profile') }}
    </p>
    <skeleton
      v-if="loader"
    />
    <error-alert :error="error" />
    <form-component
      :notification="notification"
      @submit="submit"
      @cancel="clear"
    />
  </div>
</template>
<script>
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import Skeleton from '@/views/settings/users/notifications/components/Skeleton.vue'
import FormComponent from '@/views/settings/users/notifications/components/FormComponent.vue'
import { email, required } from '@/libs/validations/validations'

export default {
  name: 'NotificationCreate',
  components: {
    FormComponent,
    Skeleton,
    ErrorAlert,
  },
  data() {
    return {
      required,
      email,
      error: {},
      loader: false,
    }
  },
  computed: {
    notification() {
      return this.$store.state[this.MODULE_NAME].notification
    },
    notificationList() {
      return this.$store.state.listModule.notificationList
    },
    warehouseList() {
      return this.$store.state.listModule.warehouseList
    },
  },
  created() {
    this.loader = true
    this.$store.commit(`${this.MODULE_NAME}/GET`, {})
    this.$store.commit('listModule/SET_NOTIFICATION_LIST', [])
    this.$store.dispatch('listModule/notificationList').then(() => {
      this.loader = false
    })
  },
  methods: {
    filterRole() {
      const role = { ...this.notification }
      role.triggers = this.notification.triggers.map(per => per.id)

      return role
    },
    submit(data, resetPermissions) {
      this.error = {}
      this.sendNotification(this, data, `${this.MODULE_NAME}/create`)
        .then(() => {
          this.clear()
          this.$router.push({ name: 'settings-users-notifications-list' })
        })
        .catch(err => {
          this.error = err.response?.data
        })
        .finally(() => {
          resetPermissions()
        })
    },
    clear() {
      this.$store.commit(`${this.MODULE_NAME}/GET`, {})
      this.$router.push({ name: 'settings-users-notifications-list' })
    },
    getProps() {
      return {
        model: this.notification,
        fields: this.fields,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'notification'

    return {
      MODULE_NAME,
    }
  },
}
</script>
